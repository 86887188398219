<template>
  <div class="result-wrap">
    <div :class="isPc ? 'result-pc' : 'result-phone'">
      <div v-if="result_flag && result_flag == '00'">
      <div class="wrap" v-if="ascendInfo">
        <div class="goodInfo">
          <div class="title"></div>
          <div class="goods_wrap">
            <div class="img_wrap" :style="productInfo.name?'width:50%':'width:100%'"> 
              <img :src="productInfo.img" class="wrap_image"/>
            </div>
            <div class="goods_info" v-if="productInfo.name && productInfo.ascendnumber && productInfo.price">
              <span class="goods_info_title" v-if="productInfo.name" >{{ productInfo.name }}</span>
              <span class="goods_info_ascendnumber" v-if="productInfo.ascendnumber"
                >防伪编码：<span class="ascendnumber">{{
                  productInfo.ascendnumber
                }}</span></span
              >
              <span class="price" v-if='productInfo.price'>¥ {{ productInfo.price }}</span>
            </div>
          </div>
          <div class="logo" > 
            <img src="../../assets/img/zhengpin.png" alt="" />
          </div>
        </div>
        <div>
          <div class="result-msg">
            <div class="agent-title">查询结果</div>
            <div class="font-css">
              <span v-if="productInfo.name" style="display: inline-block;width: 80%;">查询商品：{{ productInfo.name }}</span><br v-if="productInfo.name"/>
              <span v-if="num != 0">已被查询：【<span style="color: red">{{ num }}</span><span>】次。谨防假冒。<br/></span></span>
              <span v-if="num == 0">首次查询，请放心使用。<br/></span>
              <span>客服热线：400-8882-0919</span>
             </div>
          </div>

            <div class="ac-msg" >
              <div class="agent-title" >鉴别方法</div>
              <div class="font-ac">
                <span>1、图片中的序号应和实物标签序号一致。<br>
                      2、防伪二维码为涂层覆盖，若刮开查询应为首次查询，若出现多次查询请联系客服热线进行举报。<br>
                      3、金色膜区域随机泛白的图案与标识上的图案一致。<br>
                      4、金色随机颗粒点非印刷墨迹，可挑出。
                </span>
              </div>
            </div>
        </div>
      </div>
      <div class="no-result" v-else>
        <div class="img"></div>
        <div class="remark">
          很抱歉，暂未查询到物品信息
        </div>
      </div>
      <div style="position: absolute;bottom:10px;left:0;right:0;margin:0 auto;" v-if="!isPc">
        <div style="text-align: center;font-size: 9px">
           <span > Copyright © 江西喜尔美喜庆用品连锁有限公司<br>
            版权所有赣ICP备17001811号-1
           </span>
        </div>
      </div>
      </div>
      <div v-if='result_flag && result_flag != "00" && !isPc'>
        <div class="wrap">
          <div class="wrap-top">
            <img src="../../assets/img-bz/banner.png" alt="" v-if="ciyt_flag=='22'">
            <img src="../../assets/img-bz/banner2.jpg" alt="" v-else>
          </div>

          <div class="wrap-description" style="min-height:170Px !important">
            <div class="flex description">
              <div class="description-lf">
                  <img src="../../assets/img-bz/dizhi.png" alt="">
              </div>
              <div class="description-rg" style="display:flex">
                  <div class="cityArea">所售区域:</div>
                  <div class="city-type">【<span>{{city_type}}</span>】</div>
              </div>
            </div>
            <div class="flex description" style="min-height:50Px !important;align-items: center !important;">
              <div class="description-lf">
                  <img src="../../assets/img-bz/gonggao.png" alt="">
              </div>
              <div class="description-rg" style="line-height:20Px">
                  您所购买的是<span style="color:#C62730">{{city_name}}</span>烟花爆竹有限公司所经销的正规产品。请按燃放说明燃放!
              </div>
            </div>
            <div class="flex description" @click="handleChangeAudioVolume">
              <audio-player ref="audio" class="audio-box" id="audiBox" :audio_src="audio_src" style="width:100%"></audio-player>
            </div>
          </div>
          <div class="wrap-description result-info" :style="num>1?'height:205Px;padding-top:10Px !important':'height:159Px;'">
            <div class="flex result-infoDiv">
               <div class="lf-image">
                 <div class="result">查询结果</div>
                 <div class="result-num" v-if="num<2&&num>0">当前为首次查询。请放心使用。</div>
                 <div class="result-num" v-else>已被查询：【<span style="color:#c72e36">{{ num }}</span>】次。谨防假冒。</div>
               </div>
               <div class="rg-image">
                 <div class="image">
                   <img src="../../assets/img-bz/zhengpin.png" alt="">
                 </div>
               </div>
            </div>
            <div style="display:flex;justify-content: space-between;" v-if="num>1" class="prompt-result">
              <div class="image"><img src="../../assets/img-bz/zhuyi.png" style="width:13Px;height:13Px"></div>
              <div class="content">注意：防伪二维码为涂层覆盖，若刮开查询应为首次查询，若出现多次查询请联系客服热线进行举报。</div>
            </div>
            <div class="zx-phone">
              <div class="lf">
                咨询热线:
              </div>
              <div class="center">{{city_phone}}</div>
              <div class="rg" @click="call">
                立即咨询
              </div>
            </div>
          </div>
          <div class="wrap-description yhInfo">
            <div class="yh-type">
              <div class="yh">
                <img src="../../assets/img-bz/yhType.png">
              </div>
              <div class="overflow">
             <div class="overflow-info">
              <div class="content" style="flex-wrap: nowrap;">
                <div v-for="(item,indx) in yh_list1.list1" class="content-li"  v-if="ciyt_flag=='22'">
                  <div class="image">
                    <img :src="item.url" style="height:115Px">
                  </div>
                  <div class="title">
                    {{ item.title }}
                  </div>
                 </div>
                 <div v-for="(item,indx) in yh_list.list1" class="content-li"  v-if="ciyt_flag=='21'">
                  <div class="image">
                    <img :src="item.url" style="height:115Px">
                  </div>
                  <div class="title">
                    {{ item.title }}
                  </div>
                 </div>
              </div>
              <div class="content" style="flex-wrap: nowrap;">
                <div v-for="(item,indx) in yh_list1.list2" class="content-li"  v-if="ciyt_flag=='22'">
                  <div class="image">
                    <img :src="item.url" style="height:115Px">
                  </div>
                  <div class="title">
                    {{ item.title }}
                  </div>
                 </div>
                 <div v-for="(item,indx) in yh_list.list2" class="content-li"  v-if="ciyt_flag=='21'">
                  <div class="image">
                    <img :src="item.url" style="height:115Px">
                  </div>
                  <div class="title">
                    {{ item.title }}
                  </div>
                 </div>
              </div>
             </div>
              </div> 
            </div>
            <div class="Indicatorlight">
              <div :class="changeIcon==2?'nocheck':'check'"></div>
              <div :class="changeIcon==1?'nocheck':'check'"></div>
              </div>
          </div>
          <div class="wrap-description" style="background:#f6f6f6;height:194Px">
            <div class="yh-type">
              <div class="yh">
                <img src="../../assets/img-bz/bzType.png" alt="">
              </div>
              <div class="content" style="margin-top: 17Px;">
                <div v-for="(item,indx) in bz_list" class="content-lis">
                  <div class="image">
                    <img :src="item.url">
                  </div>
                  <div class="title">
                    {{ item.title }}
                  </div>
              </div>
              </div>
            </div>
          </div>
          <div class="wrap-description" style="height:510Px;position:relative;padding-bottom:0">
            <div class="yh-type">
              <div class="yh" style="width:55.6% !important">
                <img src="../../assets/img-bz/shuoming.png" alt="" style="widht:100%">
              </div>
              <div class="content" style="margin-top: 17Px;text-align:left;">
                <div class="content-description">
                  1、正确选择烟花爆竹的燃放地点，严禁在繁华街道剧院等公共场所和山林、有电的设施下以及靠近易燃易炸物品的地方进行燃放。燃放烟花爆竹要遵守当地政府有关的安全规定。<br>
                  2、烟花的燃放不可倒置。吐珠类烟花的燃放最好能用物体或器械固定在地面上进行，若确需手持燃放时只能用手指掐住筒体尾端，底部不要朝掌心点火后，将手臂伸直，烟花火口朝上，尾部朝地，对空发射。禁止在楼群和阳台上燃放。<br>
                  3、喷花类、小礼花类、组合类烟花燃放时，平放地面固牢，燃放中不得出现倒筒现象，点燃引线人立即离开。<br>
                  4、燃放旋转升空及地面旋转烟花，必须注意周围环境，放置平整地面，点燃引线后，离开观赏，燃放手持或线吊类旋转烟花时，手提线头或用小竹杆吊住棉线点燃后向前伸，身体勿近烟花。燃放钉挂旋转类烟花时一定要将烟花钉牢在壁或木板上，用手转动烟花，能旋转的好，才能点燃引线离开观赏。<br>
                  5、手持烟花不应朝地面方向燃放。<br>
                  6、爆竹应在屋外空处吊挂燃放，点燃后切忌将爆竹放在手中，双响炮应直竖地面，不得横放。
                </div>
                
              </div>
              <div class="description-gif">
                  <img src="../../assets/img-bz/bz.gif" alt="">
                </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if='result_flag && result_flag != "00" && isPc'>
        <div class="wrap" v-if="ascendInfo">
        <div class="goodInfo">
          <div class="title"></div>
          <div class="goods_wrap">
            <div class="img_wrap">
              <img :src="productInfo.img" class="wrap_image"/>
            </div>
            <div class="goods_info" v-if="productInfo.name && productInfo.ascendnumber && productInfo.price">
              <span class="goods_info_title" v-if="productInfo.name" >{{ productInfo.name }}</span>
              <span class="goods_info_ascendnumber" v-if="productInfo.ascendnumber"
                >防伪编码：<span class="ascendnumber">{{
                  productInfo.ascendnumber
                }}</span></span
              >
              <span class="price" v-if='productInfo.price'>¥ {{ productInfo.price }}</span>
            </div>
          </div>
          <div class="logo" > 
            <img src="../../assets/img/zhengpin.png" alt="" />
          </div>
        </div>
        <div>
          <div class="result-msg">
            <div class="agent-title">查询结果</div>
            <div class="font-css">
              <span v-if="productInfo.name" style="display: inline-block;width: 80%;">查询商品：{{ productInfo.name }}</span><br v-if="productInfo.name"/>
              <span v-if="num != 0">已被查询：【<span style="color: red">{{ num }}</span><span>】次。谨防假冒。<br/></span></span>
              <span v-if="num == 0">首次查询，请放心使用。<br/></span>
              <span>客服热线：400-8882-0919</span>
             </div>
          </div>

            <div class="ac-msg" >
              <div class="agent-title" >鉴别方法</div>
              <div class="font-ac">
                <span>1、图片中的序号应和实物标签序号一致。<br>
                      2、防伪二维码为涂层覆盖，若刮开查询应为首次查询，若出现多次查询请联系客服热线进行举报。<br>
                      3、金色膜区域随机泛白的图案与标识上的图案一致。<br>
                      4、金色随机颗粒点非印刷墨迹，可挑出。
                </span>
              </div>
            </div>
        </div>
      </div>
      </div>
      
      <div class="no-result" v-if="result_flag == '' && isPc">
        <div class="img"></div>
        <div class="remark">
          很抱歉，暂未查询到物品信息
        </div>
      </div>
      <div class="no-result" v-if="result_flag == '' && !isPc">
        <div class="img"></div>
        <div class="remark">
          很抱歉，暂未查询到物品信息
        </div>
      </div>
      </div>
  </div>
</template>
<script src="http://res.wx.qq.com/open/js/jweixin-1.6.0.js"></script>
<script>
import AudioPlayer from './audio.vue'
export default {
  name: "anti-result",
  props: ["isPc"],
  components: {
    AudioPlayer
  },
  data() {
    return {
      ciyt_flag:'',
      num:'',
      city_phone:'0561-7030968',
      city_name:'濉溪县',
      city_type:'',
      yh_list:{
        list1:[
          {
          url:require('../../assets/img-bz/sximage1.jpg'),
          title:'60发特效夜景箱'
          },
          {
            url:require('../../assets/img-bz/sximage3.jpg'),
            title:'60发特效夜景箱'
          },
          {
          url:require('../../assets/img-bz/sximage5.jpg'),
          title:'40发(专色桃红)'
          },
          {
            url:require('../../assets/img-bz/sximage8.jpg'),
            title:'40发(专色大红)'
          },
          
        ],
        list2:[
          {
          url:require('../../assets/img-bz/sximage4.jpg'),
          title:'70发精品夜景箱'
          },
          {
            url:require('../../assets/img-bz/sximage2.jpg'),
            title:'80发扇形夜景箱'
          },
          {
            url:require('../../assets/img-bz/sximage6.jpg'),
            title:'118发扇形特效箱'
          },
          {
            url:require('../../assets/img-bz/sximage7.jpg'),
            title:'60发(专色西瓜红)'
          },
        ]
      },
      yh_list1:{
        list1:[
          {
            url:require('../../assets/img-bz/image6.png'),
            title:'60发特效夜景箱'
          },
          {
            url:require('../../assets/img-bz/image7.png'),
            title:'60发特效夜景箱'
          },
          {
            url:require('../../assets/img-bz/img2.png'),
            title:'118发扇形特效箱'
          },
          {
            url:require('../../assets/img-bz/img3.png'),
            title:'168发扇形特效箱'
          }
        ],
        list2:[
          {
          url:require('../../assets/img-bz/image4.png'),
          title:'80发特效夜景箱'
          },
          {
            url:require('../../assets/img-bz/image5.png'),
            title:'100发特效夜景箱'
          },
          {
            url:require('../../assets/img-bz/img4.png'),
            title:'238发扇形特效箱'
          },
          {
          url:require('../../assets/img-bz/img1.png'),
          title:'348发扇形特效箱'
          },
          
        ]
      },
      bz_list:[
        {
          url:require('../../assets/img-bz/fgh.png'),
          title:'富贵红'
        },
        {
          url:require('../../assets/img-bz/ddh.png'),
          title:'大地红'
        },
        {
          url:require('../../assets/img-bz/qzh.png'),
          title:'千足红'
        }
      ],
      result_flag:'',
      Users_ID: "qrbhjcw8vv",
      env: "wx_lp",
      act: "get_store_pifa_ac_order",
      ascendInfo:{},
      ascendResult: "",
      image:require('../../assets/img-pc/xemno-image.png'),
      x1:'',
      x2:'',
      changeIcon:1,
      audio_src:''
    };
  },
  mounted(){
    let that = this
    window.addEventListener('touchstart',(e)=>{
      that.x1 = e.targetTouches[0].pageX
    }) 
    window.addEventListener('touchmove',(e)=>{
      that.x2 = e.targetTouches[0].pageX
      let changeX = that.x2-that.x1;
       if(changeX>0){
        that.changeIcon = 1
       }else{
        that.changeIcon = 2
       }
    }) 
  },
  computed: {
    productInfo() {
      let data = {};
      let ascendInfos = []
      if(this.ascendInfo.area){
        ascendInfos = this.ascendInfo.stores_list
      }
      if (ascendInfos.length>0) {
        let img = ascendInfos[0].Products_JSON? JSON.parse(ascendInfos[0].Products_JSON) : "";
        img = img ? (img.ImgPath.length ? img.ImgPath[0] : this.image) : this.image;
        data.price = ascendInfos[0].Products_PriceX;
        data.name = ascendInfos[0].Products_Name;
        data.img = img;
        data.ascendnumber = ascendInfos[0].prod_ascend_id;
        ascendInfos.map(value=>{
          if(value.query_amount){
           data.amount = value.query_amount;
          }
        })
      }else{
        data.img = this.image;
      }
      return data;
    }
  },
 
  methods: {
    call() {
      window.location.href = "tel:400-8820-919";
    },
    handleChangeAudioVolume() {
      // 点击页面关闭音量键显示
      if (this.$refs.audio) this.$refs.audio.audioHuds = false
    },
    timeFilter(val) {
      return this.$day(new Date(val * 1000)).format("YYYY-M-d hh:mm:ss");
    },
    getAscendInfo(antNum) {
      let datas = {
        Users_ID: this.Users_ID,
        act: this.act,
        env: "wap",
        ascend_id: antNum,
        User_ID: "",
        store_id: "1",
        access_token: "",
        sortToken: "1"
      };
      let data = new FormData();
      for (let i in datas) {
        data.append(i, datas[i]);
      }
      this.$axios
       .post(
        //"http://localhost.xemerp.com/api/v1/get_store_pifa_ac_order.html",
        "https://mall.xemerp.com/api/v1/get_store_pifa_ac_order.html",
         data,
         {
           headers: { "Content-Type": "multipart/form-data" }
         }
       )
       .then(res => {
        let result = res.data;
        this.ascendInfo = result.data.area?result.data:{};
        if (this.ascendInfo.area) {
         this.result_flag = result.data.area
         this.ciyt_flag = result.data.area
         this.city_type = result.data.area_type
         this.num = result.data.query_amount
         if(result.data.area=='22'){
           this.city_name = '霍邱县' 
           this.city_phone = '0564-6019111'
           this.audio_src = 'https://qiniu-store.xemerp.com/118c28ac5a0fb6139f1a12150b46cdd5.mp3'
          }else if(result.data.area=='21'){
           this.city_name = '濉溪县'
           this.city_phone = '0561-7030968'
           this.audio_src = 'https://qiniu-store.xemerp.com/cdd028a0ce65ac501b18f5168b00561a.mp3'
          }
        }else{
          this.result_flag = ''  
        }
        console.log(this.ascendInfo)
       });


    },
    goBack() { 
      this.$router.go(-1);
    },
  
  },

  created(e) {
    // let data = window.localStorage.getItem("antNum");
    let data = this.$route.query.fcm
    this.getAscendInfo(data);
  },
};
</script>

<style lang="less" scoped>
@import "./result.less";
@import "./resultBz.less";

::v-deep .header-container{
  display: none !important;
}

.result-phone {
  width: 100%;
  height: calc(100vh - 132px);
  overflow-y: auto;
  background-color: #EEEDED;
  .wrap {
    box-sizing: border-box;
    width: 100%;
  }
  .goodInfo {
    width: 100%;
    height: 470px;
    background-color: #ffffff;
    box-shadow: 0px 3px 30px 0px rgba(218, 227, 235, 0.5);
    // border-radius: 20px;
    padding: 20px 40px 40px;
    box-sizing: border-box;
    .title {
      font-size: 50px;
      line-height: 80px;
      padding: 20px 0 10px;
      color: #000000;
    }
    .goods_wrap {
      display: flex;
      justify-content: space-between;
      .img_wrap {
        width: 100%;
        height: 400px;
        position: relative;
        background-color: #f6f6f6;
        border-radius: 10px;
      }
      .goods_info {
        flex: 1;
        box-sizing: border-box;
        padding: 20px 0 20px 37px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        font-size: 36px;
        .goods_info_ascendnumber {
          color: #666;
          .ascendnumber {
            color: #c90f2c;
          }
        }
        .price {
          font-size: 48px;
          line-height: 72px;
          color: #333333;
        }
      }
    }
    .logo {
      width: 325.8px;
      height: 367.2px;
      position:relative;
      top:0px;
      left: 750px;
    }
  }
  .agent-title {
    letter-spacing:2px;
    font-weight: bolder;
    font-size: 48px;
    font-family:  "宋体";
    padding:  15px 0;
    margin:  15px 0;
  }

  .result-msg {
    width: 100%;
    min-height: 138Px;
    padding: 5Px 10Px 10Px;
    background-color: #ffffff;
    border-radius: 0Px 0Px 5Px 5Px;
  }

  .ac-msg {
    width: 100%;
    height: 180Px;
    padding: 0Px 10Px 10Px;
  }

  .font-css{
    font-size: 36px;
    line-height:70px;
  }
  .font-ac{
    font-size: 36px;
    line-height:70px;
  }
  .agent_wrap {
    padding: 30px;

    .agent-item {
      width: 100%;
      height: 247px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 59px;
      .left {
        font-size: 36px;
        line-height: 48px;
        color: #5c6066;
        flex: 1;
        padding-right: 24px;
        display: flex;
        justify-content: space-between;
        padding-top: 67px;
        .level {
          font-size: 36px;
          color: #5c6066;
        }
        .icon {
          width: 20px;
          height: 20px;
          box-shadow: 0px 3px 10px 0px rgba(81, 84, 91, 0.1);
          border-radius: 50%;
          background-color: #c90f2c;
          border: solid 6px #ffffff;
          margin-top: 8px;
          position: absolute;
          right: 20px;
          z-index: 10;
          &.flow-hr::after {
            content: "";
            position: absolute;
            top: 25px;
            left: 8px;
            height: 280px;
            border-left: 8px dotted #f0f2f5;
            z-index: 0;
          }
        }
      }
      .right {
        box-sizing: border-box;
        width: 800px;
        height: 100%;
        background-color: #ffffff;
        box-shadow: 0px 3px 16px 0px rgba(218, 227, 235, 0.5);
        border-radius: 10px;
        border-left: 15px solid #c90f2c;
        padding: 25px 0 25px 44px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        font-size: 30px;
        color: rgba(51, 51, 51, 0.7);
        .right_title {
          color: #999999;
          margin-right: 28px;
        }
      }
    }
  }

  .no-result {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 50%;
    .img {
      width: 442px;
      height: 442px;
      background: url("../../assets/img/no-result.png") no-repeat;
      background-size: contain;
      margin-bottom: 44px;
    }
    .remark {
      width: 442px;
      text-align: center;
      font-size: 36px;
      line-height: 63px;
      letter-spacing: 0px;
      color: #333333;
      .btn {
        color: #d2102e;
      }
    }
  }
}
 .wrap_image {
  width: 100% !important;
  height: 100% !important;
  object-fit: contain;
}

</style>
