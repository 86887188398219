<template>
  <div>
    <audio @timeupdate="updateProgress"  ref="audioRef"  id="audioRef" autoplay style="display:none" >
      <source :src="audio_src" type="audio/mp3" />
      您的浏览器不支持音频播放
    </audio>
    <div class="audio-right">
      <div class="description-lf dialogAudioPlay" v-if="audioStatus !== 'pause'" @click="playAudio" ref="playAudios">
        <img src="../../assets/img-bz/bofang.png" alt="">
      </div>
      <div v-else @click="playAudio" class="dialogAudioPlay description-lf">
        <img src="../../assets/img-bz/zanting.png" alt="">
      </div>
      <div class="audio-time" style="min-height: 10px;margin-right:5Px">
        <span class="audio-length-current" id="audioCurTime">{{ audioStart }}</span>
      </div>
      <div class="progress-bar-bg" id="progressBarBg" v-dragto="setAudioIcon" style="display:flex">
        <div class="progress-bar" id="progressBar" style="position:relative" ></div>
        <div id="radiuss" class="progress-one" v-if="actives"></div>
        <div id="radiuss" class="progress-two" v-else></div>
      </div>
      <div class="audio-time" style="min-height: 10px;margin-left:5Px">
        <span class="audio-length-total">{{ duration }}</span>
      </div>
      <div class="volume">
        <div @click.stop=" () => {return false}" class="volume-progress" v-show="audioHuds">
          <div class="volume-bar-bg" id="volumeBarBg" v-adjuster="handleShowMuteIcon">
            <div class="volume-bar" id="volumeBar"></div>
          </div>
        </div>
        <div class="description-rg dialogAudioPlay"  @click.stop="audioHuds = !audioHuds">
           <img src="../../assets/img-bz/yinliang.png" alt="">
      </div>
      </div>
    </div>
  </div>
</template>

<script src="http://res.wx.qq.com/open/js/jweixin-1.0.0.js"></script>
<script>
import wx from 'jweixin-npm';
export default {
  data() {
    return {
      actives:true,
      audioStatus: 'pause',
      audioStart: '00:00',
      duration: '00:00',
      audioVolume: 0.5,
      audioHuds: false,
      first_flag:true
    }
  },
  directives: {
    dragto: {
      inserted: function (el, binding, vnode) {
        el.addEventListener(
          'click',
          (e) => {
            let wdiv = document.getElementById('progressBarBg').clientWidth
            let audio = vnode.context.$refs.audioRef
            // 只有音乐开始播放后才可以调节，已经播放过但暂停了的也可以
            let ratemin = e.offsetX / wdiv
            let rate = ratemin * 100
            document.getElementById('progressBar').style.width = rate + '%'
            audio.currentTime = audio.duration * ratemin
            audio.play()
            binding.value()
          },
          false
        )
      }
    },
    adjuster: {
      inserted: function (el, binding, vnode) {
        el.addEventListener(
          'click',
          (e) => {
            let hdiv = document.getElementById('volumeBarBg').clientHeight
            let audio = vnode.context.$refs.audioRef
            // 只有音乐开始播放后才可以调节，已经播放过但暂停了的也可以
            let ratemin = e.offsetY / hdiv
            let rate = ratemin * 100
            document.getElementById('volumeBar').style.height = rate + '%'
            audio.volume = ratemin
            binding.value(rate / 100)
          },
          false
        )
      }
    }
  },
  computed: {
    audioIcon() {
      if (this.audioHuds) {
        return this.audioVolume < 0.01 ? 'checked icon-jingyin' : 'checked icon-shengyin'
      } else {
        return 'icon-shengyin'
      }
    }
  },
  props:{
    audio_src:String
  },
  created(){
  },
  mounted() {
    console.log()
    this.fetch()
      wx.config({
         debug: false,
         appId: '111',
         timestamp: '111',
         nonceStr: '111',
         signature: '111',
         jsApiList: []
    })
    wx.ready(()=> {
      var video = document.getElementById("audioRef");
        video.play();
    });
    return
    if(document.readyState=='complete'){
      var video = document.getElementById("audioRef");
        video.play();
    }else{
      document.addEventListener('DOMContentLoaded', function () {
      function audioAutoPlay() {
         let aduio = document.getElementById('audioRef')
         aduio.play();
          document.addEventListener("WeixinJSBridgeReady", function () {
            aduio.play();
          }, false);
      }
      audioAutoPlay();
    })   
    }
  },
  
  methods: {
    fetch() {
      let that = this
      var myVid = this.$refs.audioRef
      myVid.loop = false
      // 监听音频播放完毕
      myVid.addEventListener(
        'ended',
        function () {
          that.audioStatus = 'play'
        },
        false
      )
      if (myVid != null) {
        myVid.oncanplay = function () {
          that.duration = that.transTime(myVid.duration) // 计算音频时长
        }
        myVid.volume = 0.5 // 设置音量50%
      }
    },
    // 播放暂停控制
    playAudio() {
     this.first_flag = false
      let recordAudio = this.$refs.audioRef // 获取audio元素
      console.log(recordAudio)
      if (recordAudio.paused) {
        recordAudio.play()
        this.audioStatus = 'pause'
      } else {
        recordAudio.pause()
        this.audioStatus = 'play'
      }
    },
    // 更新进度条与当前播放时间
    updateProgress(e) {
      var value = e.target.currentTime / e.target.duration
      if (document.getElementById('progressBar')) {
        this.actives = false
        document.getElementById('progressBar').style.width = value * 100 + '%'
        document.getElementById('progressBar').style.backgroundColor = '#c62730'
        if (e.target.currentTime === e.target.duration) {
          this.audioStatus = 'pause'
        }
      } else {
        this.audioStatus = 'pause'
      }

      this.audioStart = this.transTime(this.$refs.audioRef.currentTime)
    },
    /**
     * 音频播放时间换算
     * @param {number} value - 音频当前播放时间，单位秒
     */
    transTime(time) {
      var duration = parseInt(time)
      var minute = parseInt(duration / 60)
      var sec = (duration % 60) + ''
      var isM0 = ':'
      if (minute === 0) {
        minute = '00'
      } else if (minute < 10) {
        minute = '0' + minute
      }
      if (sec.length === 1) {
        sec = '0' + sec
      }
      return minute + isM0 + sec
    },
    setAudioIcon() {
      this.audioStatus = 'pause'
    },
    handleShowMuteIcon(val) {
      this.audioVolume = val
    }
  }
}
</script>

<style lang="less" scoped>
.volume {
  position: relative;
  .volume-progress {
    position: absolute;
    top: -70Px;
    left: 6Px;
    width: 20Px;
    height: 70Px;
    background: #f6f6f6;
    border-radius: 2Px;
    padding-top: 5Px;
  }
  .volume-bar-bg {
    margin: 0 auto;
    width:5Px;
    height: 60Px;
    background: #dcdcdc;
    border-radius: 50Px;
    flex: 1;
    position: relative;
    transform: rotate(180deg);
    cursor: pointer;
    .volume-bar {
      width:5Px;
      height: 50%;
      background: #c62730;
      border-radius: 50Px;
    }
  }
  .checked {
    color: #c62730;
  }
}
.audio-right {
  width: 100%;
  height:30Px;
  line-height: 30Px;
  background: #f6f6f6;
  border-radius: 3Px;
  display: flex;
  padding-right: 5Px;
  .dialogAudioPlay {
    cursor: pointer;
    color: #5c5e66;
    font-size: 10Px;
  }
  .progress-one{
    width:7Px;
    height:7Px;
    background:#c62730;
    border-radius: 50%;
    position:absolute;
    margin-left:-6Px;
    margin-top:-1Px
  }
  .progress-two{
    width:7Px;
    height:7Px;
    background:#c62730;
    border-radius: 50%;
    margin-left:-6Px;
    margin-top:-1Px
  }
 
  .progress-bar-bg {
    background-color: #fff;
    flex: 1;
    position: relative;
    height: 5Px;
    top: 50%;
    width: 61.5% !important;
    transform: translateY(-50%);
    margin-top: -1Px;
    cursor: pointer;
  }
  .progress-bar {
    background-color:#fff;
    width: 100%;
    height: 5Px;
    border-radius: 3Px;
  }
  .audio-time {
    width:32Px;
    overflow: hidden;
    font-size: 7Px;
    .audio-length-total {
      float: right;
      font-size: 10Px;
      font-family: PingFang SC;
      color: #7F7E82;
    }
    .audio-length-current {
      float: left;
      font-size: 10Px;
      font-family: PingFang SC;
      color: #7F7E82;
    }
  }
}
.description-lf{
        width: 30Px;
        margin-right: 10Px;
        height: 30Px;
        img{
         width: 100%;
         height: 100%;
        }
      }
  .description-rg{
    width: 12Px;
    height: 11Px;
    margin-left: 10Px;
  }
</style>